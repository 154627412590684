import React, {useEffect, useState} from 'react';
import Img from 'gatsby-image';
import { useStaticQuery, graphql } from 'gatsby';
import { Wrapper, MapDiv, DataDiv } from './styles';


export const Content = () => {

  const [w, setW] = useState(0);

  useEffect(() => setW(window.innerWidth), [])
  const conData = useStaticQuery(graphql`
  query conData {
    site {
      siteMetadata {
        eMail
        address
        phoneNo
        phoneNo1
        nipNumber
        brand
        facebook
        instagram
      }
    }
    allImageSharp(filter: {fluid: {originalName: {eq: "main-logo.png"}}}) {
      edges {
        node {
          id
          fluid {
            base64
            aspectRatio
            src
            srcSet
            sizes
            originalImg
            originalName
          }
        }
      }
    }
  }`);

  const styleImg = {
    width: w > 768 ? '60%' : '60%',
    resizeMode: 'contain',
  }

  return (
    <Wrapper>
      <MapDiv>
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d9701.760934403406!2d17.38364214669855!3d52.56164957146533!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4704893667c0323f%3A0x85abda89c1623e34!2s62-270%20Waliszewo!5e0!3m2!1spl!2spl!4v1633282920785!5m2!1spl!2spl" width="100%" height="60%"  allowfullscreen="" loading="lazy" style={{ borderWidth: 0}} title="map"></iframe>
      </MapDiv>
      <DataDiv>
        <Img 
            alt="Artis-Pro Logo"
            style = {styleImg} 
            fluid={conData.allImageSharp.edges[0].node.fluid} 
          />
          <article>
            <p>E-mail: {conData.site.siteMetadata.eMail}</p>
            <p>Adres: {conData.site.siteMetadata.address}</p>
            <p>Tel. {conData.site.siteMetadata.phoneNo1}</p>
            <p>NIP: {conData.site.siteMetadata.nipNumber}</p>
          </article>
      </DataDiv>
    </Wrapper>

  )
}