import React from 'react';
import { Layout } from '../components/common';
import { Header } from '../components/theme';
import { Content, Banner } from '../components/contact';
import SEO from '../components/seo';

export default class Contact extends React.Component {

  render() {
    return (
      <Layout>
        <SEO title="Kontakt" />
        <Header />
        <Banner />
        <Content />
      </Layout>
    )
  }
}