import React from 'react';
import Img from 'gatsby-image';
import { useStaticQuery, graphql } from 'gatsby';
import { Main, BannCover } from './styles';

export const Banner = () => {

  const data = useStaticQuery(graphql`
    query bannImg {
      allImageSharp(filter: {fluid: {originalName: {eq: "0001.webp"}}}) {
        edges {
          node {
            id
            fluid {
              base64
              aspectRatio
              src
              srcSet
              sizes
              originalImg
              originalName
            }
          }
        }
      }
    }  
  `);

  return (
    <Main>
      <Img fluid={data.allImageSharp.edges[0].node.fluid} style={{width: '100%', height: '100%', position: 'relative'}}/>
      <BannCover>
        <h1>Skontaktuj się z nami!</h1>
      </BannCover>
    </Main>
  )
}