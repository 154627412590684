import styled from 'styled-components';

export const Main = styled.div`
  width: 100%;
  height: 220px;
  background: #c22c34;
  position: relative;
  font-family: 'Noto Sans Display', sans-serif;
  
`;

export const BannCover = styled.div`
  width: 80%;
  height: 80%;
  position: absolute;
  top: 0;
  left: 0;
  background: #c22c34;;
  justify-content: flex-start;
  align-items: center;
  display: flex;
  margin: 1% 10%;

  & h1 {
    color: #fff;
    font-size: 3.1rem;
    letter-spacing: .8px;
    font-weight: 400;
    margin-left: 4%;

    @media (max-width: 960px) {
      font-size: 1.4rem;
      margin-left: 2%;
    }
  }

  @media (max-width: 960px) {
    margin: 6% 10%;
  }
`