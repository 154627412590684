import styled from 'styled-components';

export const Wrapper = styled.div`
  background: #fff;
  display: flex;
  margin: 0 10%;
  flex-direction: row;
  font-family: 'Noto Sans Display', sans-serif;

  @media (max-width: 960px) {
    flex-direction: column;
  }
`;

export const MapDiv = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  height: 80vh;
  width: 50%;

  @media (max-width: 960px) {
    width: 100%;
    height: 40vh;
  }
`;

export const DataDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 80vh;
  width: 50%;

 & p:nth-child(1) {
   margin-top: 24px;
 }

  & p {
    margin: 7px 0;
    letter-spacing: .5px;
    font-size: 1.2rem;
    color: #c22c34;
    text-align: center;
  }

  @media (max-width: 960px) {
    width: 100%;
    height: 30vh;
  }
`;